<template>
  <!-- Table Container Card -->
  <div>
    <add-new-users
      :is-add-new-users-sidebar-active.sync="isAddNewUsersSidebarActive"
      @clickaddNewUser="clickaddNewUser"
    />
    <add-edit-users
      :is-add-edit-users-sidebar-active.sync="isAddEditUsersSidebarActive"
      :editDataProps="editDataProps"
      @clickEditUser="clickEditUser"
    />
    <add-change-password
      :is-add-change-password-sidebar-active.sync="
        isAddChangePasswordSidebarActive
      "
      :changePasswordId="changePasswordId"
      @clickaddChangePasswordUser="clickaddChangePasswordUser"
    />
    <assign-permission-user
      :is-assign-permission-user-sidebar-active.sync="
        isAssignPermissionUserSidebarActive
      "
      :assignUserId="assignUserId"
      @clickAssignUser="clickAssignUser"
    />
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          > -->
          <!-- <b-breadcrumb :items="items" /> -->

          <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          <!-- </b-col> -->

          <!-- Search -->
          <b-col cols="12" md="6" v-if="addpermissionHideButton == true">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                v-if="removeButtons == true"
                @click="addNewUsers()"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <span v-for="level_data in table_data" :key="level_data.Level">
        {{level_data}}
      </span> -->

      <b-table-simple
        v-if="addpermissionHideButton == true"
        hover
        caption-top
        responsive
        class="rounded-bottom mb-0"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>No.</b-th>
            <b-th>User Name</b-th>
            <b-th>User Image</b-th>
            <b-th>Email</b-th>
            <b-th>Role Name</b-th>
            <b-th v-if="removeButtons == true">Action</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(users_data, index) in getUsers()">
            <b-tr :key="users_data._id">
              <b-td>
                {{ index + 1 }}
              </b-td>
              <b-td>
                {{ users_data.name }}
              </b-td>
              <b-td>
                <b-avatar size="50" class="mr-1" :src="users_data.avatar" />
                <!-- <b-img rounded :src="users_data.avatar" height="80" /> -->
              </b-td>

              <b-td> {{ users_data.email }}</b-td>
              <!-- <b-td> </b-td> -->
              <b-td>
                <div
                  style="margin-bottom: 10%; margin-top: 7px"
                  v-for="(roleName, ind) in users_data.role_name"
                  :key="ind + 'roleName'"
                >
                  <b-badge
                    pill
                    variant="primary"
                    style="background: #6666; color: black"
                  >
                    {{ roleName }}
                  </b-badge>

                  <br />
                </div>
                <div
                  style="margin-bottom: 10%; border-bottom: 2px solid #6666"
                ></div>

                <div
                  v-for="(data, indexThree) in users_data.classAccess"
                  :key="indexThree + 'data'"
                >
                  <b-badge
                    pill
                    variant="primary"
                    style="background: #6666; color: black"
                  >
                    {{ data }}
                  </b-badge>

                  <br />
                </div>

                <div
                  v-for="(subjectdata, indexFour) in users_data.subjectAccess"
                  :key="indexFour + 'subjectdata'"
                >
                  <b-badge
                    pill
                    variant="primary"
                    style="background: #6666; color: black"
                  >
                    {{ subjectdata }}
                  </b-badge>

                  <br />
                </div>
              </b-td>

              <b-td style="height: 100px" v-if="removeButtons == true">
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <!-- <b-dropdown-item
                    @click="
                      addRoleToUser(users_data._id, users_data.role_name[0])
                    "
                  >
                    <feather-icon height="20px" width="20px" icon="AwardIcon" />
                    <span class="align-middle ml-50">Assign Role</span>
                  </b-dropdown-item> -->
                  <b-dropdown-item
                    @click="
                      editUserData(
                        users_data._id,
                        users_data.avatar,
                        users_data.name,
                        users_data.email,
                        users_data.role_name[0],
                        users_data.classids,
                        users_data.subjectsids,
                        users_data.primaryColor,
                        users_data.secondaryColor,
                        users_data.tretiaryColor
                      )
                    "
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="editChangePassword(users_data._id)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Change Password</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteUserAlert(users_data._id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-td>
            </b-tr>
          </template>

          <!-- <b-tr> -->
        </b-tbody>
      </b-table-simple>
      <!-- {{ this.table_data.level }} -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BImg,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { BBreadcrumb } from "bootstrap-vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddNewUsers from "./app/list/users-list/AddNewUsers.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AddEditUsers from "./app/list/users-list/AddEditUsers.vue";
import AddChangePassword from "./app/list/users-list/AddChangePassword.vue";
import AssignPermissionUser from "./app/list/users-list/AssignPermissionUser.vue";
export default {
  components: {
    AddNewUsers,
    AddEditUsers,
    AssignPermissionUser,
    AddChangePassword,
    BBreadcrumb,
    BCard,
    BRow,
    ToastificationContent,
    BCol,
    BImg,

    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      table_data: {},
      searchQuery: "",
      isAddNewUsersSidebarActive: false,
      isAddEditUsersSidebarActive: false,
      editDataProps: {},
      DeleteUserAlertMessage: "",
      isAddChangePasswordSidebarActive: false,
      changePasswordId: "",
      isAssignPermissionUserSidebarActive: false,
      assignUserId: {
        id: "",
        roleName: "",
      },
      addpermissionHideButton: false,
      removeButtons: "",
    };
  },
  created() {
    this.gettabledata();
  },
  mounted() {
    var newPermission = JSON.parse(localStorage.getItem("permission"));
    for (let index = 0; index < newPermission.permission.length; index++) {
      if (newPermission.permission[index] == "manage_users") {
        this.removeButtons = true;
        // this.forcRenderSubject();
        // console.log("this.removeButtons", this.removeButtons);
        break;
      } else {
        this.removeButtons = false;
      }
    }
  },
  methods: {
    confirmText(i, j) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          this.delete_role_from_user(i, j);
        }
      });
    },
    deleteUserAlert(i) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          this.deleteUser(i);
        }
      });
    },
    deleteUser(id) {
      var userDeleteId = {
        id: "",
      };
      userDeleteId.id = id;
      axios
        .post(process.env.VUE_APP_API_URL + "/user/delete", userDeleteId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.DeleteUserAlertMessage = response.data;
          this.userDelete("success");
          this.gettabledata();
        });
    },
    userDelete(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.DeleteUserAlertMessage,
          variant,
        },
      });
    },
    delete_role_from_user(role_id, index) {
      // console.log("aaaaaaaaaaa",this.table_data)
      // return "done";
      // var newId = this.table_data.permission_ids[data];/
      var deleted = {
        user_id: "",
        role_id_index: "",
      };
      deleted.user_id = role_id;
      deleted.role_id_index = index;
      axios
        .post(process.env.VUE_APP_API_URL + "/delete_role_from_user", deleted)
        .then((response) => {
          // console.log(response);
          if (response.data == "Successfully Remove") {
            this.removeRole("success", "top-left");
          } else {
            this.removeRolereject("danger", "top-left");
          }
          this.gettabledata();
        });
    },
    removeRole(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Remove role Successfully ",
            variant,
          },
        },
        {
          position,
        }
      );
    },
    removeRolereject(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Remove role fail.Try again ",
            variant,
          },
        },
        {
          position,
        }
      );
    },
    clickAssignUser(assign_role) {
      axios
        .post(process.env.VUE_APP_API_URL + "/assign_role_data", assign_role)
        .then((response) => {
          // console.log(response.data);
          // if (response.data != "duplicate role") {
          //   this.addNewRoleSuccess("success", "top-left");
          // } else {
          //   this.addNewURoleReject("danger", "top-left");
          // }

          this.gettabledata();
        });
    },
    addRoleToUser(id, roleName) {
      // console.log("roleName",roleName)

      this.assignUserId.id = id;
      this.assignUserId.roleName = roleName;
      this.isAssignPermissionUserSidebarActive = true;
    },
    editUserData(
      id,
      avatar,
      name,
      email,
      roleName,
      classAccess,
      subjectAccess,
      first,
      second,
      third
    ) {
      this.editDataProps = {};
      // console.log();
      this.editDataProps.id = id;
      this.editDataProps.avatar = avatar;
      this.editDataProps.name = name;
      this.editDataProps.email = email;
      if (first) {
        this.editDataProps.first = first;
      } else {
        this.editDataProps.first = "";
      }
      if (second) {
        this.editDataProps.second = second;
      } else {
        this.editDataProps.second = "";
      }
      if (third) {
        this.editDataProps.third = third;
      } else {
        this.editDataProps.third = "";
      }

      if (roleName == "admin") {
        this.editDataProps.roleName = roleName;
        // this.editDataProps.classAccess = classAccess;
        // this.editDataProps.subjectAccess = subjectAccess;
      } else {
        this.editDataProps.roleName = roleName;
        this.editDataProps.classAccess = classAccess;
        this.editDataProps.subjectAccess = subjectAccess;
      }
      // console.log('this.editDataProps',this.editDataProps)
      //  console.log("this.editDataProps",this.editDataProps);

      this.isAddEditUsersSidebarActive = true;
    },
    clickaddNewUser: function (add_user_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/register", add_user_data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log("response.data.message", response.data.message);
          // "errors":{"email":["The email has already been taken."]}}
          if (response.data.success == true) {
            this.addNewUserReject("success");
          }
          // this.gettabledata();
          this.gettabledata();
        })
        .catch(function (error) {
          // console.log("error.response", error.response);
          this.addNewUserToast("danger");
          if (error.response) {
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },

    addNewUserReject(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "User Added successfully",
          variant,
        },
      });
    },
    addNewUserToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "The email has already been taken.",
          variant,
        },
      });
    },
    clickEditUser: function (edit_user_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/user/data/edit", edit_user_data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.data != "duplicate email") {
            this.showToa("success");
            this.gettabledata();
          } else {
            this.showToast("danger");
          }
          // this.gettabledata();
        });
    },
    clickaddChangePasswordUser: function (change_password_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/user/change/password",
          change_password_data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(response.message.d);
          if (response.data.message == "Password Update") {
            this.changepasswordSuccess("success", "top-right");
          } else {
            this.changepasswordreject("danger", "top-right");
          }

          this.gettabledata();
        });
    },
    changepasswordreject(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Password not change",
            variant,
          },
        },
        {
          position,
        }
      );
    },
    changepasswordSuccess(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Password change successfully",
            variant,
          },
        },
        {
          position,
        }
      );
    },
    showToa(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Edit successfully",
          variant,
        },
      });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Soory! User not edit try another email",
          variant,
        },
      });
    },

    editChangePassword(id) {
      // console.log("id of user",id);
      this.isAddChangePasswordSidebarActive = true;
      this.changePasswordId = id;
    },
    addNewUsers() {
      this.isAddNewUsersSidebarActive = true;
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/cms/users/data", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data.length == 0) {
            this.addpermissionHideButton = false;
          } else {
            this.addpermissionHideButton = true;
            this.table_data = get_response.data;
          }

          // console.log(get_response.data);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    getUsers() {
      //  this.forcRender();
      if (this.searchQuery.trim().length > 0) {
        return this.table_data.filter((item) => {
          // console.log(item);
          if (item.name) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
