<template>
  <b-sidebar
    id="assign-permission-user-sidebar"
    :visible="isAssignPermissionUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-assign-permission-user-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Assign Role</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <b-form-group label="New Role" label-for="New-Role">
            <!-- {{ selected }} -->
            <!-- <b-form-select
              id="example-input-3"
              name="example-input-3"
              v-model="selected"
              :options="SelectType"
              v-on:change="checkType(selected)"
              aria-describedby="input-3-live-feedback"
            ></b-form-select> -->
            <v-select
              v-model="selected"
              :close-on-select="true"
              :options="table_data"
              label="name"
              input-id="add-guests"
            >
              <!-- <template #option="{ avatar, name }">
                <b-avatar size="sm" :src="avatar" />
                <span class="ml-50 align-middle"> {{ name }}</span>
              </template>

              <template #selected-option="{ avatar, name }">
                <b-avatar size="sm" class="border border-white" :src="avatar" />
                <span class="ml-50 align-middle"> {{ name }}</span>
              </template> -->
            </v-select>
          </b-form-group>

          <div
            class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              style="margin-left: 10px"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
  BAvatar,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import countries from "../../../../@fake-db/data/other/countries";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BSidebar,
    BAvatar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAssignPermissionUserSidebarActive",
    event: "update:is-assign-permission-user-sidebar-active",
  },
  props: {
    isAssignPermissionUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    assignUserId: {
      type: Object,
      required: true,
    },
  },
  // watch: {
  //   (val) {
  //     console.log("tabledata xxxx", this.assignUserId);
  //     this.selected = this.assignUserId.roleName;
  //   },
  // },
  watch: {
    isAssignPermissionUserSidebarActive(val) {
      // console.log("dddddd", this.assignUserId);
      this.selected = this.assignUserId.roleName;
      // this.checkType(this.selected);
      // this.changetoschema(this.editdata);
    },
  },
  //   mixins: [togglePasswordVisibility],
  data() {
    return {
      selected: "",
      saveAssignValueArray: [],

      required,
      alphaNum,
      email,
      countries,
      roledata: {},
      table_data: [],
      add_new_role_data: "",
    };
  },
  created() {
    this.gettabledata();
  },
  //   computed: {
  //     passwordToggleIcon() {
  //       return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  //     },
  //   },
  methods: {
    // SelectedPermssion(name) {
    //   console.log("console array select permission", name);
    // },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.roledata = {};
      this.selected = "";
    },
    onSubmit() {
      this.roledata.id = this.assignUserId.id;
      //   console.log("selected", this.selected);
      this.roledata.assignRole = this.selected;

      this.add_new_role_data = this.roledata;
      // console.log(this.add_new_role_data);
      //   console.log("selected", this.add_new_role_data);
      this.$emit("clickAssignUser", this.add_new_role_data);
      this.$root.$emit(
        "bv::toggle::collapse",
        "assign-permission-user-sidebar"
      );
      //   if (this.roledata.password == this.roledata.password_confirmation) {
      //     this.add_new_user_data = this.roledata;
      //     this.$emit("clickaddNewUser", this.add_new_user_data);
      //     this.$root.$emit("bv::toggle::collapse", "add-new-users-sidebar");
      //   } else {
      //     this.showToast("danger", "top-left");
      //     // alert("Please confirm your password first");
      //   }

      //   console.log(this.add_new_level_data);
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/roles/data/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;

          console.log(get_response.data);
        });
    },
    showToast(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Please confirm your password first",
            variant,
          },
        },
        {
          position,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
